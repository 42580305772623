import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { PortfolioService } from "../../providers/services/portfolio.service";
import { DataService } from "../../providers/services/data.service";
import { Location } from '@angular/common';

@Component({
  selector: 'app-story-text',
  templateUrl: './story-text.page.html',
  styleUrls: ['./story-text.page.scss'],
})
export class StoryTextPage implements OnInit {
  nextButtonEnabled: boolean = false;
  storyText: string = '';
  constructor(
    private router: Router,
    private apiProvider: PortfolioService,
    private data: DataService,
    private location: Location
  ) { }

  ngOnInit() { }

  onStoryChange(text) {
    const wordsCount = text.trim().split(' ').length;
    console.log(wordsCount, (wordsCount >= 3))
    this.nextButtonEnabled = (wordsCount >= 3);
  }

  getLocation() {
    // Mountain Everest as fallback location
    // Literally in the middle of nowhere lol
    const fallbackLocation = {
      latitude: 27.9881201,
      longitude: 86.9249751
    };

    return new Promise((resolve) => {
      fetch('https://ipapi.co/json/')
        .then((response) => response.json())
        .then((json) => {
          const {latitude, longitude} = json;
          resolve({ latitude, longitude });
        })
        .then(function() {
          resolve(fallbackLocation);
        })
        .catch(() => {
          resolve(fallbackLocation);
        });
    });
  }

  async openIngredientsCombination() {
    const location: any = await this.getLocation();
    localStorage.setItem('location', JSON.stringify(location));

    const story = {
      type: 'text',
      language: 'en',
      original_generated_text: this.storyText.trim(),
      personality: true
    }

    Promise.all([
    new Promise((resolve) => {
      this.apiProvider.uploadStory(story).subscribe((res: any) => {

        console.log('api uploadStory response', res);

        /* ==== navigate back on error ==== */
        if (!res || !res.id && res.length == 1) {
          this.location.back();
          alert('Server error: ' + res[0]);
          return;
        }

        this.data.setCurrentSuggestionId(res.id);
        this.data.setIngredients(res.resultat, 'prepared');
        this.data.setPersonality(res.personality);

        resolve(true);

      })
    }),
    new Promise((resolve) => {
      this.apiProvider.getAllIngredients().subscribe((res: any) => {

        const data = res.results
        const ingredients = data.map(item => Object(
          {
            name: item.name,
            image: item.featured_image.image,
            pk: item.pk,
            emotions: item.emotions,
            description: item.description
          }))
        this.data.setIngredients(ingredients, 'all');

        resolve(true);
      })
    })
    ]).then(() => {
      /* ==== navigate to results ==== */
      // this.router.navigate(['/story-ingredients-results']);
      this.router.navigateByUrl('/story-ingredients-prepare?loaded=true');
    });




    /* ==== navigate to prepare page with loader ==== */
    this.router.navigate(['/story-ingredients-prepare']);
  }

}
