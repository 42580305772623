import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ingredient-item',
  templateUrl: './ingredient-item.component.html',
  styleUrls: ['./ingredient-item.component.scss'],
})
export class IngredientItemComponent implements OnInit {
  @Input() item: any;
  constructor() {}

  ngOnInit() {
    this.item.flip = false;
    let emotions = this.item.emotions.replace('.', '').split(',');
    emotions = emotions.splice(0, 3).map(item => item.trim()).join('\n');
    this.item.emotions = emotions.length ? emotions : 'No description';
  }

  flipItem() {
    this.item.flip = !this.item.flip
  }
}
