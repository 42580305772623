import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {map, delay} from "rxjs/operators";
import {of} from "rxjs/internal/observable/of";
import {itemId, Portfolio, Transaction} from "../interfaces";
import {environment} from "../../../environments/environment.prod";
import {Auth} from "./auth.service";
import {TransactionsService} from "./transactions.service";


@Injectable({
    providedIn: 'root'
})
export class PortfolioService {
    apiUrl: string = environment.apiUrl;
    token: string = localStorage.getItem('token') ? localStorage.getItem('token') : 'WCFVK9AnWMomOuqQ2Gz5eDswaWOiU8';

    httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data', Authorization: `Api-Key ${this.token}`, })
    };

    httpOptionsTokenOnly = {
      headers: new HttpHeaders({ Authorization: `Api-Key ${this.token}`, })
    };

    constructor(private http: HttpClient, private authService: Auth) {

    }

    uploadStory(data: any) {
      return this.http.post<any>(`${this.apiUrl}api/uploading/story/`, data, this.httpOptionsTokenOnly)
    }

    getSuggestions() {
      return this.http.get<any>(`${this.apiUrl}api/uploading/suggestion/`, this.httpOptions)
    }

    getSuggestionById(id, selected_notes, mode) {

      const location = localStorage.getItem('location') ? JSON.parse(localStorage.getItem('location')) : {};

      const data = {
        added_notes: [],
        selected_notes: selected_notes,
        mode: [mode],
        ...location
      }
      return this.http.patch<any>(`${this.apiUrl}api/uploading/suggestion/${id}/`, data, this.httpOptionsTokenOnly)
    }

    getPerfumeById(id: number) {
      return this.http.get<any>(`${this.apiUrl}api/ada/perfume/${id}/`, this.httpOptions);
    }

    submitPerfumeFeedback(product: number, action_type: string) {
      return this.http.post<any>(`${this.apiUrl}clients/perfume-feedback/`, { product, action_type }, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Api-Key ${this.token}`, })
      });
    }

    submitPerfumeAppFeedback(feedback: boolean) {
      return this.http.post<any>(`${this.apiUrl}clients/app-feedback/`, { feedback }, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Api-Key ${this.token}`, })
      });
    }

    getAllIngredients() {
      return this.http.get<any>(`${this.apiUrl}api/ada/note/?limit=1000`, this.httpOptions)
    }

    submitPersonalityFeedback(feedback: boolean = true) {
      return this.http.post<any>(`${this.apiUrl}clients/personality-feedback/`, { feedback }, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Api-Key ${this.token}`, })
      });
    }
}
