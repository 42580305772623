// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    apiUrl: 'https://api.ohmynote.com/',
    cunaUrl:'https://api.kuna.io/v3/',
    firebaseConfig: {
        apiKey: "AIzaSyAUeZahgbAexP9lEPjtZk2uSvbiopfdA7k",
        dbUrl: "https://crypto-portfolio-3f56c-default-rtdb.europe-west1.firebasedatabase.app/",
        authUrl: "https://identitytoolkit.googleapis.com/v1/",
        authDomain: "crypto-portfolio-3f56c.firebaseapp.com",
        projectId: "crypto-portfolio-3f56c",
        storageBucket: "crypto-portfolio-3f56c.appspot.com",
        messagingSenderId: "267683562038",
        appId: "1:267683562038:web:098c056dc5cadbc371ce97"
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
