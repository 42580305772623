import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment.prod";

@Injectable({
    providedIn: 'root',
})

export class ApiService {
    //url: string = environment.apiUrl
    url: string = environment.firebaseConfig.dbUrl

    recordedBlobs:any = [];

    constructor(public http: HttpClient) {
    }

    get(endpoint: string, params?: any): Observable<any> {
        return this.http.get(this.url + endpoint, params)
    }

    post(endpoint: string, body: any, reqOpts?: any): Observable<any> {
        return this.http.post(this.url + endpoint, body, reqOpts)
    }

    put(endpoint: string, body: any, reqOpts?: any): Observable<any> {
        return this.http.put(this.url + endpoint, body, reqOpts)
    }

    delete(endpoint: string, reqOpts?: any): Observable<any> {
        return this.http.delete(this.url + endpoint, reqOpts)
    }

    patch(endpoint: string, body: any, reqOpts?: any): Observable<any> {
        return this.http.put(this.url + endpoint, body, reqOpts)
    }
}
