import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-subheader-tabs',
  templateUrl: './subheader-tabs.component.html',
  styleUrls: ['./subheader-tabs.component.scss'],
})
export class SubheaderTabsComponent implements OnInit {
  @Input() activeTabs: number = 3;

  constructor() {}

  ngOnInit() {}
}
