import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-subheader-gender-filters',
  templateUrl: './subheader-gender-filters.component.html',
  styleUrls: ['./subheader-gender-filters.component.scss'],
})
export class SubheaderGenderFiltersComponent implements OnInit {
  @Output() activeFilter = new EventEmitter<string>();
  items: any = [
    {
      text: 'Male',
      key: 'male',
      selected: false,
      isIcon: true,
    },
    {
      text: 'Female',
      key: 'female',
      selected: false,
      isIcon: true,
    },
    {
      text: 'Unisex',
      key: 'unisex',
      selected: false,
      isIcon: true,
    },
  ];
  constructor() {}

  ngOnInit() {}

  changeFilter(value, i) {
    console.log(value, i);
    this.items.map((item) => (item.selected = false));
    this.items[i].selected = true;

    this.activeFilter.emit(this.items[i].key);
  }
}
