import {Injectable} from "@angular/core";
import {AuthResponse, User} from "../interfaces";
import {HttpClient} from "@angular/common/http";
import {ApiService} from "./api.service";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {Platform} from "@ionic/angular";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment.prod";

@Injectable({
    providedIn: 'root',
})
export class Auth {
    token = null
    authUrl: string = environment.firebaseConfig.authUrl
    apiKey: string = environment.firebaseConfig.apiKey
    userId: string = localStorage.getItem('user-id')
    userEmail: string = localStorage.getItem('user-email')


    constructor(private http: HttpClient,
                private api: ApiService,
                private platform: Platform,
                private router: Router) {
        this.platform.ready().then(() => {
            this.checkLoggedIn()
        })
    }

    checkLoggedIn(): void {
        this.token = localStorage.getItem('auth-token')
    }

    setToken(token): void {
        this.token = token
    }

    getToken(): string {
        return this.token
    }

    isAuth(): boolean {
        return Boolean(this.token)
    }

    logout(): void {
        this.setToken(null)
        this.userId = null
        localStorage.clear()
    }

    register(user: User): Observable<AuthResponse> {
        return this.http.post<AuthResponse>(`${this.authUrl}accounts:signUp?key=${this.apiKey}`, user)
    }

    login(user: User): Observable<AuthResponse> {
        return this.http.post<AuthResponse>(`${this.authUrl}accounts:signInWithPassword?key=${this.apiKey}`, user)
            .pipe(tap(({idToken, localId}) => {
                localStorage.setItem('user-email', user.email)
                localStorage.setItem('user-id', localId)
                localStorage.setItem('auth-token', idToken)
                this.userId = localId
                this.setToken(idToken)
            }))
    }

    // Utils
    authError({error}) {
        return error.error.message
    }

}
