import { Component, OnInit } from '@angular/core';
import { AlertController, MenuController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { Auth } from './providers/services/auth.service';
import { Network } from '@capacitor/network';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  isAuth: boolean;
  userEmail: string;
  alertPresented: boolean = false;
  loading: boolean = true;

  constructor(
    private router: Router,
    private platform: Platform,
    public authService: Auth,
    private menu: MenuController,
    public alertController: AlertController,
    private translate: TranslateService
  ) {
    this.initializeApp();
  }

  ngOnInit() {}

  initializeApp() {
    // other stuff...

    let currentLanguage = 'en';

    if (this.translate.getBrowserLang() === 'fr') {
      currentLanguage = 'fr';
    }

    this.translate.setDefaultLang(currentLanguage);
    // Check connection
    this.checkConnection();

    /* IF WEB then navigate to Home Page */
    const isNative = Capacitor.isNative;

    // TOKEN URL EXAMPLE:
    // http://localhost:4200/#/token/JcUfL0noKGp0vZZ7Cjj5e5p8rZhjDF

    /* Set token from url */
    if (location.href.indexOf('#') > -1) {
      const token = location.href.split('#')[1].split('/')[2];
      localStorage.setItem('token', token);
    }

    if (!isNative) {
      this.router.navigate(['/home']);
      return;
    }

    /* Get token from storage */
    const token = localStorage.getItem('token');

    // alert('is token:' + token);

    /* If token is set then navigate to home, else to scanner page */
    if (token) {
      // await Browser.open({ url: 'https://web.omynote.io/' });
      this.router.navigate(['/home']);
      // const openCapacitorSite = async () => {
      //   alert('browser open')
      //   await Browser.open({ url: 'https://web.omynote.io/' });
      //   alert('browser end')
      // };
    } else {
      this.router.navigate(['/welcome-scanner']);
    }
  }

  checkConnection() {
    Network.addListener('networkStatusChange', (status) => {
      console.log(status, 'status!');
      !status.connected ? this.presentAlert() : this.alertController.dismiss();
    });
  }

  menuClose() {
    this.menu.close();
  }

  menuOpen() {
    this.menu.open();
    console.log(this.authService.userEmail, 'this.authService.userEmail');
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Connection problem...',
      buttons: [],
      backdropDismiss: false,
    });

    await alert.present();
  }
}
