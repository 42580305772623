import { Injectable } from '@angular/core';

export interface PersonalityDTO {
  scores: PersonalityScores;
  highest_emotion: PersonalityHighestEmotion;
}

export interface PersonalityScores {
  fear: number;
  anger: number;
  anticipation: number;
  trust: number;
  surprise: number;
  positive: number;
  negative: number;
  sadness: number;
  disgust: number;
  joy: number;
}

export interface PersonalityHighestEmotion {
  id: number;
  emotion_name: string;
  personality_name: string;
  personality_name_fr: string;
  personality_description: string;
  personality_description_fr: string;
}

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private ingredients: any = {
    prepared: [],
    selected: [],
    all: []
  };
  private personality: PersonalityDTO = null;
  private suggestionId: string;
  private suggestions: any = [];

  constructor() { }

  setIngredients(data, type) {
    this.ingredients[type] = data;
  }

  setPersonality(data: PersonalityDTO) {
    this.personality = data;
  }

  getPersonality() {
    return this.personality;
  }

  getIngredients(type) {
    return this.ingredients[type];
  }

  setSuggestions(data, id) {
    this.suggestions = data;
    this.suggestionId = id;
  }

  getCurrentSuggestionId() {
    return this.suggestionId;
  }

  setCurrentSuggestionId(id) {
    this.suggestionId = id;
  }


}
